<template>
  <card type="chart">
    <LoadingSpinner :show="loading" />
    <template slot="header">
      <h5 class="card-category">
        <i class="tim-icons icon-map-big text-success"></i> Gráfico Regiões
      </h5>
      <h2 class="card-title">{{ total }}</h2>
    </template>
    <div class="chart-area">
      <line-chart
        style="height: 100%"
        chart-id="green-line-chart"
        :chart-data="locationLineChart.chartData"
        :gradient-stops="locationLineChart.gradientStops"
        :extra-options="locationLineChart.extraOptions"
      >
      </line-chart>
    </div>
  </card>
</template>

<script>
import LineChart from "@/components/Charts/LineChart";
import * as chartConfigs from "@/components/Charts/config";
import LoadingSpinner from "@/components/Spinner.vue";
import config from "@/config";

const defaultOptions = {
  fill: true,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBorderColor: "rgba(255,255,255,0)",
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
};
const options = {
  ...defaultOptions,
  label: "Região",
  borderColor: config.colors.teal,
  pointBackgroundColor: config.colors.teal,
  pointHoverBackgroundColor: config.colors.teal,
};

export default {
  name: "LocationChart",
  components: {
    LineChart,
    LoadingSpinner,
  },
  props: {
    startDate: String,
    endDate: String,
    selectedMachine: String,
  },
  data() {
    return {
      hasStartDate: null,
      hasEndDate: null,
      hasMachineSelected: null,
      loading: false,
      total: 0,
      locationLineChart: {
        extraOptions: chartConfigs.greenChartOptions,
        chartData: {
          labels: [],
          datasets: [
            {
              label: "Região",
              ...options,
              data: [0],
            },
          ],
        },
        gradientColors: [
          "rgba(66,134,121,0.15)",
          "rgba(66,134,121,0.0)",
          "rgba(66,134,121,0)",
        ],
        gradientStops: [1, 0.4, 0],
      },
    };
  },
  methods: {
    createChart({ datasets, labels, chartName }) {
      this[chartName].chartData = { datasets, labels };
    },
    createLocationsChart(locationsDataset) {
      if (!locationsDataset) return;
      const { data, labels } = locationsDataset;
      const datasets = [{ ...options, data, label: "Região" }];
      this.createChart({
        datasets,
        labels,
        chartName: "locationLineChart",
      });
    },
    validateStartAndFinalDate() {
      return this.endDate || this.startDate;
    },
    async getLocations() {
      this.loading = true;
      const response = await this.$http.get(
        `/graph/locations?start_date=${this.startDate}&end_date=${this.endDate}`
      );
      const { locations } = response.data;
      this.loading = false;
      return locations;
    },
    async generateLocationDataset() {
      try {
        const locations = await this.getLocations();
        let totalOfLocation = 0;
        for (let location of locations) {
          totalOfLocation += location.total;
        }
        this.total = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(Math.abs(totalOfLocation / 100));
        locations
          .sort(function (productA, productB) {
            return productA.total - productB.total;
          })
          .reverse();
        if (locations) {
          const locationsChartData = locations.reduce(
            (acc, obj) => {
              acc.labels.push(`${obj.city}`);
              acc.data.push(obj.total);
              return acc;
            },
            { labels: [], data: [] }
          );
          localStorage.setItem(
            "@Snackly:locationsChartData",
            JSON.stringify(locationsChartData)
          );
          return locationsChartData;
        }
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async generateLocationChart() {
      if (!this.validateStartAndFinalDate()) return;
      this.hasStartDate = this.startDate;
      this.hasEndDate = this.endDate;
      const locationsDataset = await this.generateLocationDataset();
      this.createLocationsChart(locationsDataset);
    },
  },
  watch: {
    selectedMachine() {
      this.hasMachineSelected = this.selectedMachine;
      this.generateLocationChart();
    },
    startDate() {
      this.generateLocationChart();
    },
    endDate() {
      this.generateLocationChart();
    },
  },
};
</script>
<style></style>