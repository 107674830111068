<template>
  <card type="chart">
    <LoadingSpinner :show="loading" />
    <template slot="header">
      <h5 class="card-category">
        <i class="tim-icons icon-tablet-2 text-info"></i> Gráfico Máquinas
      </h5>
      <h2 class="card-title">{{ totalMachines }}</h2>
    </template>
    <div class="chart-area" style="height: 450px">
      <div class="box pr-4">
        <horizontal-bar
          style="height: 100%"
          chart-id="blue-bar-chart"
          :chart-data="machineBarChart.chartData"
          :gradient-stops="machineBarChart.gradientStops"
          :extra-options="machineBarChart.extraOptions"
        >
        </horizontal-bar>
      </div>
    </div>
  </card>
</template>

<script>
import HorizontalBar from "@/components/Charts/HorizontalChart";
import * as chartConfigs from "@/components/Charts/config";
import LoadingSpinner from "@/components/Spinner.vue";
import config from "@/config";

const defaultOptions = {
  fill: true,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBorderColor: "rgba(255,255,255,0)",
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
};
const options = {
  ...defaultOptions,
  label: "Máquinas",
  borderColor: config.colors.info,
  pointBackgroundColor: config.colors.info,
  pointHoverBackgroundColor: config.colors.info,
};

export default {
  name: "MachineChart",
  components: {
    HorizontalBar,
    LoadingSpinner,
  },
  props: {
    startDate: String,
    endDate: String,
    selectedMachine: String,
  },
  data() {
    return {
      totalMachines: 0,
      hasStartDate: null,
      hasEndDate: null,
      hasMachineSelected: null,
      loading: false,
      machineBarChart: {
        extraOptions: chartConfigs.blueChartOptions,
        chartData: {
          labels: [],
          datasets: [
            {
              ...options,
              data: [0],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
      },
    };
  },
  methods: {
    createChart({ datasets, labels, chartName }) {
      this[chartName].chartData = { datasets, labels };
    },
    createMachinesChart(machinesDataset) {
      if (!machinesDataset) return;
      const { data, labels } = machinesDataset;
      const datasets = [{ ...options, data, label: "Máquinas" }];
      this.createChart({
        datasets,
        labels,
        chartName: "machineBarChart",
      });
    },
    validateStartAndFinalDate() {
      return this.endDate || this.startDate;
    },
    async getMachines() {
      this.loading = true;
      const response = await this.$http.get(
        `/graph/machines?start_date=${this.startDate}&end_date=${this.endDate}`
      );
      const { machines } = response.data;
      this.loading = false;
      return machines;
    },
    async generateMachinesDataset() {
      try {
        let machines = await this.getMachines();
        if (this.selectedMachine) {
          machines = machines.filter(
            (machine) => machine.machine_id == this.selectedMachine
          );
        }
        let totalOfMachine = 0;
        for (let machine of machines) {
          totalOfMachine += machine.total;
        }
        this.totalMachines = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(Math.abs(totalOfMachine / 100));
        machines
          .sort(function (machineA, machineB) {
            return machineA.total - machineB.total;
          })
          .reverse();
        if (machines) {
          const machinesChartData = machines.reduce(
            (acc, obj) => {
              acc.labels.push(obj.description);
              acc.data.push(obj.total);
              return acc;
            },
            { labels: [], data: [] }
          );
          localStorage.setItem(
            "@Snackly:machineChartData",
            JSON.stringify(machinesChartData)
          );
          return machinesChartData;
        }
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async generateMachineChart() {
      if (!this.validateStartAndFinalDate()) return;
      this.hasStartDate = this.startDate;
      this.hasEndDate = this.endDate;
      const machinesDataset = await this.generateMachinesDataset();
      this.createMachinesChart(machinesDataset);
    },
  },
  watch: {
    selectedMachine() {
      this.hasMachineSelected = this.selectedMachine;
      this.generateMachineChart();
    },
    startDate() {
      this.generateMachineChart();
    },
    endDate() {
      this.generateMachineChart();
    },
  },
};
</script>
<style></style>